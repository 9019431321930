import React, { useEffect, useState } from "react";
import { useApiCall } from "./useApiCall";

export const useSignUp = () => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [signUpError, setSignUpError] = useState("");
  const [signUpStatus, setSignUpStatus] = useState("idle");

  const signUp = (data) => {
    const requestOptions = {
      url: "/users/create",
      method: "POST",
      data,
    };

    executeApiCall(requestOptions);

    setSignUpStatus("pending");
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse) {
          const { data: requestData } = apiCallResponse;

          const { error, success } = requestData;

          if (error) {
            setSignUpStatus("error");
            setSignUpError(error.userMessage);
          }

          if (success === true) {
            setSignUpStatus("success");
          }
        }
        break;
    }
  }, [apiCallResponse, apiCallStatus]);

  return {
    signUp,
    signUpError,
    signUpStatus,
  };
};
