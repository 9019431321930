import { cloneDeep } from "lodash";

export const setActiveContentShouldLimitMaxCharacters = ({
  contents,
  activeContentId,
  shouldLimitMaxCharacters,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.maxCharacters = {
        ...content.maxCharacters,
        shouldLimitMaxCharacters,
      };
    }

    return cloneDeep(content);
  });
};

export default setActiveContentShouldLimitMaxCharacters;
