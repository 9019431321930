import React, { createContext, useReducer, useEffect, useState } from "react";
import surveyResponseReducer from "../reducers/surveyResponseReducer";

import { useFetchSurveyResponse } from "../../services/hooks/useFetchSurveyResponse";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
};

// Initial state
const initialState = {
  id: null,
  surveyId: null,
  userId: null,
  contents: [],
  created: null,
};

// Create context
export const SurveyResponseContext = createContext(initialState);

// Provider component
export const SurveyResponseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(surveyResponseReducer, initialState);

  const {
    fetchSurveyResponse,
    resetFetchSurveyResponse,
    fetchedSurveyResponse,
    fetchSurveyResponseError,
  } = useFetchSurveyResponse();

  const [activeContentId, setActiveContentId] = useState(null);
  const [activeContent, setActiveContent] = useState(null);
  const [initialingSurveyResponseId, setInitialingSurveyResponseId] =
    useState(null);

  const setSurveyResponseState = (payload) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload,
    });
  };

  const resetActiveSurveyResponse = (surveyResponseId) => {
    let canReset = true;

    if (surveyResponseId) {
      if (surveyResponseId !== state.id) {
        canReset = false;
      }
    }

    if (canReset) {
      setInitialingSurveyResponseId(null);
      setSurveyResponseState(initialState);
      resetFetchSurveyResponse();
    }
  };

  useEffect(() => {
    if (initialingSurveyResponseId) {
      fetchSurveyResponse(initialingSurveyResponseId);
    }
  }, [initialingSurveyResponseId]);

  useEffect(() => {
    if (fetchedSurveyResponse) {
      setSurveyResponseState(fetchedSurveyResponse);
    }
  }, [fetchedSurveyResponse]);

  useEffect(() => {
    if (!activeContentId) {
      if (state.contents.length) {
        setActiveContentId(state.contents[0].id);

        return;
      }
    } else {
      setActiveContent(
        (state.contents ?? []).filter(
          (content) => content.id === activeContentId
        )[0]
      );
    }
  }, [state.contents, activeContentId]);

  const value = {
    ...state,
    activeContentId,
    activeContent,
    setInitialingSurveyResponseId,
    setActiveContentId,
    surveyResponseError: fetchSurveyResponseError,
    resetActiveSurveyResponse,
  };

  return (
    <SurveyResponseContext.Provider value={value}>
      {children}
    </SurveyResponseContext.Provider>
  );
};
