import React, { createContext, useReducer, useEffect } from "react";
import authReducer from "../reducers/authReducer";
import { useGlobal } from "../../services/hooks/useGlobal";
import { useRouter } from "next/router";
import isValidUser from "../../util/isValidUser";
import isValidToken from "../../util/isValidToken";

//Actions
export const ACTIONS = {
  SET_STATE: "SetState",
  SET_ACCESS_TOKEN: "SetAccessToken",
  SET_USER: "SetUser",
  SET_IS_SUBSCRIBED: "SetIsSubscribed",
  SET_SUBSCRIPTION_STATUS: "SetSubscriptionStatus",
  SET_AUTH_TOKEN_VERIFICATION_STATUS: "SetAuthTokenVerificationStatus",
  SET_IS_AUTH_TOKEN_VERIFIED: "SetIsAuthTokenVerified",
  SET_NEXT_ACCESS_TOKEN_REFRESH_AT: "SetNextAccessTokenRefreshAt",
  SET_IS_ACCESS_TOKEN_EXPIRED: "SetIsAccessTokenExpired",
  SET_AUTH_REFRESH_WORKER: "SetAuthRefreshWorker",
  SET_AUTH_STATUS: "SetAuthStatus",
  SET_REFRESH_STATUS: "SetRefreshStatus",
};

// Initial state
const initialState = {
  accessToken: null,
  user: null,
  isSubscribed: false,
  isAuthTokenVerified: false,
  subscriptionStatus: "idle",
  authTokenVerificationStatus: "idle",
  nextAccessTokenRefreshAt: null,
  authRefreshWorker: null,
  authStatus: "idle",
  refreshStatus: "idle",
};

// Create context
export const AuthContext = createContext(initialState);

// Provider component
export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const { apiBaseUrl } = useGlobal();

  const router = useRouter();

  const setAccessToken = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACCESS_TOKEN,
      payload,
    });
  };

  const setUser = (payload) => {
    dispatch({
      type: ACTIONS.SET_USER,
      payload,
    });
  };

  const setIsSubscribed = (payload) => {
    dispatch({
      type: ACTIONS.SET_IS_SUBSCRIBED,
      payload,
    });
  };

  const setSubscriptionStatus = (payload) => {
    dispatch({
      type: ACTIONS.SET_SUBSCRIPTION_STATUS,
      payload,
    });
  };

  const setAuthTokenVerificationStatus = (payload) => {
    dispatch({
      type: ACTIONS.SET_AUTH_TOKEN_VERIFICATION_STATUS,
      payload,
    });
  };

  const setIsAuthTokenVerified = (payload) => {
    dispatch({
      type: ACTIONS.SET_IS_AUTH_TOKEN_VERIFIED,
      payload,
    });
  };

  const setNextAccessTokenRefreshAt = (payload) => {
    dispatch({
      type: ACTIONS.SET_NEXT_ACCESS_TOKEN_REFRESH_AT,
      payload,
    });
  };

  const setAuthRefreshWorker = (payload) => {
    dispatch({
      type: ACTIONS.SET_AUTH_REFRESH_WORKER,
      payload,
    });
  };

  const setAuthStatus = (payload) => {
    dispatch({
      type: ACTIONS.SET_AUTH_STATUS,
      payload,
    });
  };

  const setRefreshStatus = (payload) => {
    dispatch({
      type: ACTIONS.SET_REFRESH_STATUS,
      payload,
    });
  };

  const refreshAuth = () => {
    if (state.authRefreshWorker) {
      state.authRefreshWorker.postMessage({});

      setRefreshStatus({
        refreshStatus: "pending",
      });
    }
  };

  const resetAuth = () => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload: initialState,
    });
  };

  useEffect(() => {
    setAuthStatus({
      authStatus: "pending",
    });

    setSubscriptionStatus({
      subscriptionStatus: "pending",
    });
  }, []);

  useEffect(() => {
    if (state.refreshStatus === "ready") {
      if (state.user && state.accessToken) {
        if (state.subscriptionStatus === "ready") {
          setAuthStatus({
            authStatus: "ready",
          });
        }
      }

      if (!state.user && !state.accessToken) {
        setAuthStatus({
          authStatus: "ready",
        });
      }
    }
  }, [
    state.user,
    state.accessToken,
    state.refreshStatus,
    state.subscriptionStatus,
  ]);

  useEffect(async () => {
    if (!state.user) {
      const { aid } = router.query;

      if (aid) {
        const response = await fetch(`${apiBaseUrl}/auth/tokens/verify`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: aid }),
        });

        const { data } = await response.json();

        if (data) {
          const { verified, user, token } = data;

          setIsAuthTokenVerified({
            isAuthTokenVerified: verified,
          });

          if (isValidUser(user)) {
            setUser({
              user,
            });
          }

          if (isValidToken(token)) {
            setAccessToken({
              accessToken: token,
            });
          }
        }

        setAuthTokenVerificationStatus({
          authTokenVerificationStatus: "ready",
        });
      } else {
        refreshAuth();
      }
    }
  }, [state.user, state.authRefreshWorker, router]);

  useEffect(async () => {
    if (state.user && state.accessToken) {
      const response = await fetch(
        `${apiBaseUrl}/users/${state.user.id}/subscribed`,
        {
          headers: {
            Authorization: state.accessToken,
            Accept: "application/json",
          },
          credentials: "include",
        }
      );

      const { data } = await response.json();

      if (data) {
        const { isSubscribed } = data;

        setIsSubscribed({ isSubscribed });

        setSubscriptionStatus({
          subscriptionStatus: "ready",
        });
      }
    }
  }, [state.user, state.accessToken]);

  useEffect(() => {
    if (!state.authRefreshWorker) {
      setAuthRefreshWorker({
        authRefreshWorker: new Worker("/js/workers/authRefreshWorker.js"),
      });
    }

    if (state.authRefreshWorker) {
      state.authRefreshWorker.onmessage = (e) => {
        const { user, token } = e.data;

        if (isValidUser(user)) {
          setUser({
            user,
          });
        } else {
          setUser({
            user: null,
          });
        }

        if (isValidToken(token)) {
          setAccessToken({
            accessToken: token,
          });
        }

        setRefreshStatus({
          refreshStatus: "ready",
        });
      };
    }
  }, [state.authRefreshWorker]);

  const value = {
    ...state,
    refreshAuth,
    setAccessToken,
    setUser,
    setIsSubscribed,
    setSubscriptionStatus,
    setAuthTokenVerificationStatus,
    setIsAuthTokenVerified,
    setNextAccessTokenRefreshAt,
    resetAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
