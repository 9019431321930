import { cloneDeep } from "lodash";

export const setActiveContentShowQuotation = ({
  contents,
  activeContentId,
  showQuotation,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.showQuotation = showQuotation;
    }

    return cloneDeep(content);
  });
};

export default setActiveContentShowQuotation;
