import { ACTIONS } from "../contexts/globalState";

const globalReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }

    case ACTIONS.SET_PAGE_TRANSITION: {
      const { isPageTransitioning } = action.payload;

      return { ...state, isPageTransitioning };
    }

    case ACTIONS.SET_GEOLOCATION: {
      const { geolocation } = action.payload;

      return { ...state, geolocation };
    }

    case ACTIONS.SHOW_OVERLAY_LOADER: {
      const { type, message, subMessage, subMessageTransitionDelay } =
        action.payload;

      return {
        ...state,
        isShowingOverlayLoader: true,
        overlayLoaderMessage: message ?? null,
        overlayLoaderSubMessage: subMessage ?? null,
        overlayLoaderSubMessageTransitionDelay:
          subMessageTransitionDelay ?? null,
        overlayLoaderType: type,
      };
    }

    case ACTIONS.HIDE_OVERLAY_LOADER: {
      return {
        ...state,
        isShowingOverlayLoader: false,
        overlayLoaderMessage: null,
        overlayLoaderSubMessage: null,
        overlayLoaderSubMessageTransitionDelay: null,
        overlayLoaderType: null,
      };
    }

    case ACTIONS.START_API_REQUEST: {
      const numApiRequestsPending = state.numApiRequestsPending + 1;

      return {
        ...state,
        numApiRequestsPending,
      };
    }

    case ACTIONS.COMPLETE_API_REQUEST: {
      const numApiRequestsPending = state.numApiRequestsPending;
      const numApiRequestsCompleted = state.numApiRequestsCompleted + 1;

      if (numApiRequestsCompleted === numApiRequestsPending) {
        return {
          ...state,
          numApiRequestsPending: 0,
          numApiRequestsCompleted: 0,
        };
      }

      return {
        ...state,
        numApiRequestsCompleted,
      };
    }

    case ACTIONS.CLEAR_API_REQUESTS: {
      return {
        ...state,
        numApiRequestsPending: 0,
        numApiRequestsCompleted: 0,
      };
    }

    case ACTIONS.SET_PAGE_DESCRIPTION: {
      const { pageDescription } = action.payload;

      return {
        ...state,
        pageDescription,
      };
    }

    case ACTIONS.SET_PAGE_TITLE: {
      const { pageTitle } = action.payload;

      return {
        ...state,
        pageTitle,
      };
    }

    case ACTIONS.SET_SHOW_NAV: {
      const { showNav } = action.payload;

      return {
        ...state,
        showNav,
      };
    }

    case ACTIONS.SET_CREATE_SHIPMENT_MODAL_ARGS: {
      return {
        ...state,
        createShipmentModalArgs: action.payload,
        isShowingCreateShipmentModal: true,
      };
    }

    case ACTIONS.SET_IS_SHOWING_CREATE_SHIPMENT_MODAL: {
      const { isShowingCreateShipmentModal } = action.payload;

      return {
        ...state,
        createShipmentModalArgs: {},
        isShowingCreateShipmentModal,
      };
    }

    case ACTIONS.SET_CONFIRM_PASSWORD_MODAL_ARGS: {
      return {
        ...state,
        confirmPasswordModalArgs: action.payload,
        isShowingConfirmPasswordModal: true,
      };
    }

    case ACTIONS.SET_CONFIRM_PASSWORD_MODAL_EXECUTION_STATUS: {
      return {
        ...state,
        confirmPasswordModalArgs: {
          ...state.confirmPasswordModalArgs,
          executionStatus: action.payload.executionStatus,
        },
      };
    }

    case ACTIONS.SET_IS_SHOWING_CONFIRM_PASSWORD_MODAL: {
      const { isShowingConfirmPasswordModal } = action.payload;

      return {
        ...state,
        confirmPasswordModalArgs: {},
        isShowingConfirmPasswordModal,
      };
    }

    case ACTIONS.SET_CONFIRM_ACTION_MODAL_ARGS: {
      return {
        ...state,
        confirmActionModalArgs: action.payload,
        isShowingConfirmActionModal: true,
      };
    }

    case ACTIONS.SET_CONFIRM_ACTION_MODAL_EXECUTION_STATUS: {
      return {
        ...state,
        confirmActionModalArgs: {
          ...state.confirmActionModalArgs,
          executionStatus: action.payload.executionStatus,
        },
      };
    }

    case ACTIONS.SET_IS_SHOWING_CONFIRM_ACTION_MODAL: {
      const { isShowingConfirmActionModal } = action.payload;

      return {
        ...state,
        confirmActionModalArgs: {},
        isShowingConfirmActionModal,
      };
    }

    default: {
      return state;
    }
  }
};

export default globalReducer;
