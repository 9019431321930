import React from "react";
import Head from "next/head";

export default function GlobalHead({ description }) {
  return (
    <Head>
      <meta charSet="utf-8" />

      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />

      <title>Admin - Chic Beauty Box</title>

      <meta name="title" content="Admin - Chic Beauty Box" />

      <meta name="robots" content="noindex" />

      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />

      <meta name="language" content="English" />

      <meta name="author" content="Chic Beauty Inc." />

      {/* <!-- JS Global Compulsory  --> */}
      <script src="/vendor/jquery/dist/jquery.min.js" />
      <script src="/vendor/jquery-migrate/dist/jquery-migrate.min.js" />
      <script src="/vendor/bootstrap/dist/js/bootstrap.bundle.min.js" />

      {/* <!--Open Source Libraries/Plugins --> */}
      <script src="/vendor/select2/dist/js/select2.full.js" />
      <script src="/vendor/quill/dist/quill.js" />
      <script src="/vendor/dropzone/dist/dropzone.js" />
      <script src="/vendor/@fancyapps/fancybox/dist/jquery.fancybox.min.js" />
      <script src="/vendor/datatables/media/js/jquery.dataTables.js" />
      <script src="/vendor/datatables.net.extensions/select/select.min.js" />
      <script src="/vendor/@yaireo/tagify/dist/tagify.min.js" />
      <script src="/vendor/sortablejs/dist/sortable.umd.js" />
      <script src="/vendor/flatpickr/dist/flatpickr.min.js" />

      {/* <!--Custom Plugins --> */}
      <script src="/vendor/hs-navbar-vertical-aside/hs-navbar-vertical-aside.js" />
      <script src="/vendor/hs-unfold/dist/hs-unfold.js" />
      <script src="/vendor/hs-form-search/dist/hs-form-search.js" />
      <script src="/vendor/hs-nav-scroller/dist/hs-nav-scroller.js" />
    </Head>
  );
}
