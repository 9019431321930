export const setActiveContentText = ({ contents, activeContentId, text }) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.content.text = text;
    }

    return content;
  });
};

export default setActiveContentText;
