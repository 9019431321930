import React from "react";

export default function Loader({
  absoluteCenter = false,
  verticalCenter = false,
  inline = false,
  small = false,
  type = "primary",
}) {
  if (inline) {
    return (
      <div
        className={`spinner-border text-${type} ml-2 mb-1 ${
          small ? "spinner-border-sm" : ""
        }`}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  }

  if (absoluteCenter) {
    return (
      <div
        className={`d-flex justify-content-center text-${type} absolute-center`}
      >
        <div
          className={`spinner-border ${small ? "spinner-border-sm" : ""}`}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (verticalCenter) {
    return (
      <div
        className={`d-flex justify-content-center text-${type} vertical-center`}
      >
        <div
          className={`spinner-border ${small ? "spinner-border-sm" : ""}`}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className={`d-flex justify-content-center text-${type}`}>
      <div
        className={`spinner-border ${small ? "spinner-border-sm" : ""}`}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}
