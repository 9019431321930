import { cloneDeep } from "lodash";

export const setActiveContentCalculateTimeToComplete = ({
  contents,
  activeContentId,
  calculateTimeToComplete,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.calculateTimeToComplete = calculateTimeToComplete;
    }

    return cloneDeep(content);
  });
};

export default setActiveContentCalculateTimeToComplete;
