import { cloneDeep } from "lodash";

export const setActiveContentRandomizeOptions = ({
  contents,
  activeContentId,
  randomizeOptions,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.randomizeOptions = randomizeOptions;
    }

    return cloneDeep(content);
  });
};

export default setActiveContentRandomizeOptions;
