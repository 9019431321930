import userSchema from "../services/validations/userSchema";
import errorCodes from "../services/errors/errorCodes";

export default async function isValidUser(user) {
  try {
    if (!user) {
      return false;
    }

    const isValid = await userSchema.validateAsync(user);

    return isValid ? true : false;
  } catch (e) {
    const error = errorCodes("UI-2001");

    return false;
  }
}
