import { cloneDeep } from "lodash";

export const addActiveContentPictureChoiceOption = ({
  contents,
  activeContentId,
  option,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      if (!content.options.some(({ id }) => id === option.id)) {
        content.options = [...content.options, option];
      }
    }

    return cloneDeep(content);
  });
};

export default addActiveContentPictureChoiceOption;
