import { useEffect, useState } from "react";
import { useApiCall } from "./useApiCall";

export const useSaveSurvey = () => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [saveSurveyStatus, setSaveSurveyStatus] = useState("idle");
  const [savedSurvey, setSavedSurvey] = useState();
  const [saveSurveyError, setSaveSurveyError] = useState();

  const saveSurvey = (data) => {
    setSaveSurveyError();
    setSavedSurvey();

    const requestOptions = {
      url: `/surveys`,
      method: "PUT",
      data,
    };

    setSaveSurveyStatus("pending");
    executeApiCall(requestOptions);
  };

  const resetSaveSurvey = () => {
    setSaveSurveyStatus("idle");
    setSavedSurvey();
    setSaveSurveyError();
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.error) {
          setSaveSurveyError(apiCallResponse.data.error.userMessage);
          setSaveSurveyStatus("ready");
        } else {
          setSaveSurveyError();

          if (apiCallResponse.data.data) {
            const { survey } = apiCallResponse.data.data;

            setSaveSurveyStatus("ready");
            setSavedSurvey(survey);
          }
        }
        break;
      case "error":
        setSaveSurveyStatus("ready");
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    saveSurvey,
    savedSurvey,
    saveSurveyError,
    saveSurveyStatus,
    resetSaveSurvey,
  };
};
