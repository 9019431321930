import { useState, useEffect } from "react";
import { useApiCall } from "./useApiCall";

export const useFetchSurvey = () => {
  const [fetchedSurvey, setFetchedSurvey] = useState();
  const [fetchSurveyStatus, setFetchSurveyStatus] = useState("idle");
  const [fetchSurveyError, setFetchSurveyError] = useState();
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();

  const fetchSurvey = (surveyId) => {
    setFetchSurveyError();

    if (surveyId) {
      const requestOptions = {
        url: `/surveys/${surveyId}`,
      };

      executeApiCall(requestOptions);

      setFetchSurveyStatus("pending");
    }
  };

  const resetFetchSurvey = () => {
    setFetchSurveyStatus("idle");
    setFetchSurveyError();
    setFetchedSurvey();
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse?.data?.data) {
          const { survey } = apiCallResponse.data.data;

          setFetchedSurvey(survey);
          setFetchSurveyStatus("ready");
        }

        if (apiCallResponse?.data?.error) {
          const { error } = apiCallResponse.data;

          setFetchSurveyError(error.userMessage);
          setFetchSurveyStatus("ready");
        }
        break;
      case "error":
        setFetchSurveyStatus("ready");
        break;
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    fetchedSurvey,
    fetchSurvey,
    fetchSurveyStatus,
    fetchSurveyError,
    resetFetchSurvey,
  };
};
