import React, {
  createContext,
  useReducer,
  useState,
  useEffect,
  useRef,
} from "react";
import surveyReducer from "../reducers/surveyReducer";

import { useFetchSurvey } from "../../services/hooks/useFetchSurvey";
import { useSaveSurvey } from "../../services/hooks/useSaveSurvey";
import { usePublishSurvey } from "../../services/hooks/usePublishSurvey";
import { useSnackbar } from "../../services/hooks/useSnackbar";
import { isEqual, pickBy } from "lodash";

//Actions
export const ACTIONS = {
  SORT_CONTENTS_LIST: "SortContentsList",
  SORT_ACTIVE_CONTENT_OPTIONS: "SortActiveContentOptions",
  SET_STATE: "SetState",
  SET_SURVEY_TYPE: "SetSurveyType",
  SET_SURVEY_NAME: "SetSurveyName",
  SET_SURVEY_TRIGGER: "SetSurveyTrigger",
  SET_SURVEY_OFFER_REWARD: "SetSurveyOfferReward",
  SET_SURVEY_RESTRICTIONS: "SetSurveyRestrictions",
  SET_SURVEY_MAX_RESPONSES: "SetSurveyMaxResponses",
  SET_SURVEY_LOYALTY_POINTS_OFFER: "SetSurveyLoyaltyPointsOffer",
  SET_SURVEY_START_TIME: "SetSurveyStartTime",
  SET_SURVEY_END_TIME: "SetSurveyEndTime",
  SET_SURVEY_EMAIL_NOTIFICATIONS: "SetSurveyEmailNotifications",
  SET_SURVEY_SMS_NOTIFICATIONS: "SetSurveySmsNotifications",
  SET_ACTIVE_CONTENT_ID: "SetActiveContentId",
  SET_ACTIVE_CONTENT: "SetActiveContent",
  SET_ACTIVE_CONTENT_TEXT: "SetActiveContentText",
  SET_ACTIVE_CONTENT_DESCRIPTION: "SetActiveContentDescription",
  SET_ACTIVE_CONTENT_REQUIRED: "SetActiveContentRequired",
  SET_ACTIVE_CONTENT_ALLOW_MULTIPLE_SELECTION:
    "SetActiveContentAllowMultipleSelection",
  SET_ACTIVE_CONTENT_RANDOMIZE_OPTIONS: "SetActiveContentRandomizeOptions",
  SET_ACTIVE_CONTENT_ALLOW_OTHER_OPTION: "SetActiveContentAllowOtherOption",
  SET_ACTIVE_CONTENT_IMAGE: "SetActiveContentImage",
  SET_ACTIVE_CONTENT_IMAGE_ALIGNMENT: "SetActiveContentImageAlignment",
  SET_ACTIVE_CONTENT_CALCULATE_TIME_TO_COMPLETE:
    "setActiveContentCalculateTimeToComplete",
  SET_ACTIVE_CONTENT_BUTTON_TEXT: "SetActiveContentButtonText",
  SET_ACTIVE_CONTENT_MULTICHOICE_OPTION_VALUE:
    "SetActiveContentMultiChoiceOptionValue",
  SET_ACTIVE_CONTENT_SHOULD_LIMIT_MAX_CHARS:
    "SetActiveContentShouldLimitMaxCharacters",
  SET_ACTIVE_CONTENT_MAX_CHARACTERS_LIMIT: "SetActiveContentMaxCharactersLimit",
  SET_ACTIVE_CONTENT_SHOW_QUOTATION: "SetActiveContentShowQuotation",
  SET_ACTIVE_CONTENT_SHOW_LABELS: "SetActiveContentShowLabels",
  SET_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION_IMAGE:
    "SetActiveContentPictureChoiceOptionImage",
  SET_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION_LABEL:
    "SetActiveContentPictureChoiceOptionLabel",
  SET_ACTIVE_CONTENT_OPTION_IS_SELECTABLE: "SetActiveContentOptionIsSelectable",
  SET_ACTIVE_CONTENT_HAS_BUTTON: "SetActiveContentHasButton",
  SET_ACTIVE_CONTENT_BUTTON_HAS_LINK: "SetActiveContentButtonHasLink",
  SET_ACTIVE_CONTENT_BUTTON_LINK: "SetActiveContentButtonLink",
  ADD_CONTENT: "AddContent",
  ADD_ACTIVE_CONTENT_MULTICHOICE_OPTION: "AddActiveContentMultiChoiceOption",
  ADD_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION:
    "AddActiveContentPictureChoiceOption",
  REMOVE_CONTENT: "RemoveContent",
  REMOVE_ACTIVE_CONTENT_MULTICHOICE_OPTION:
    "RemoveActiveContentMultiChoiceOption",
  REMOVE_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION:
    "RemoveActiveContentPictureChoiceOption",
};

// Initial state
const initialState = {
  id: null,
  activeContentId: null,
  surveyType: null,
  surveyName: null,
  reward: {
    offer: false,
    loyaltyPoints: null,
  },
  restrictions: {
    loggedIn: false,
    unsubscribed: false,
    subscribed: false,
    canceled: false,
    allowPopup: false,
  },
  triggers: {
    path: null,
  },
  maxResponses: null,
  schedule: {
    startTime: null,
    endTime: null,
  },
  notifications: {
    email: null,
    sms: null,
  },
  contents: [],
  responses: [],
  publishedAt: null,
  lastUpdateTimestamp: null,
  created: null,
};

export const SURVEY_MODES = {
  CREATOR: "creator",
  VIEWER: "viewer",
};

export const SURVEY_ID_LENGTH = 18;

// Create context
export const SurveyContext = createContext(initialState);

// Provider component
export const SurveyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(surveyReducer, initialState);

  const { addSnackbar } = useSnackbar();

  const {
    fetchSurvey,
    fetchSurveyError,
    fetchSurveyStatus,
    fetchedSurvey,
    resetFetchSurvey,
  } = useFetchSurvey();

  const {
    saveSurvey,
    savedSurvey,
    saveSurveyError,
    saveSurveyStatus,
    resetSaveSurvey,
  } = useSaveSurvey();

  const {
    publishSurvey,
    publishedSurvey,
    publishSurveyError,
    publishSurveyStatus,
    resetPublishSurvey,
  } = usePublishSurvey();

  const [activeContent, setActiveContent] = useState(null);
  const [initialingSurveyId, setInitialingSurveyId] = useState(null);
  const [initializationError, setInitializationError] = useState();
  const [surveyMode, setSurveyMode] = useState(SURVEY_MODES.VIEWER);
  const [isSurveyWorkspaceReady, setIsSurveyWorkspaceReady] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [activeContentImageIsUploading, setActiveContentImageIsUploading] =
    useState(false);

  const activeContentIndexRef = useRef(null);
  const idleTimeoutRef = useRef(null);

  const isPublished =
    state.lastUpdateTimestamp && state.publishedAt
      ? state.lastUpdateTimestamp <= state.publishedAt
      : false;

  const initializeSurvey = (surveyId, { creatorMode }) => {
    if (surveyId) {
      setInitialingSurveyId(surveyId);

      if (typeof creatorMode === "boolean") {
        if (creatorMode === true) {
          setSurveyMode(SURVEY_MODES.CREATOR);
        } else {
          setSurveyMode(SURVEY_MODES.VIEWER);
        }
      }
    }
  };

  const resetSurvey = (surveyId) => {
    let canReset = true;

    if (surveyId) {
      if (surveyId !== state.id) {
        canReset = false;
      }
    }

    if (canReset) {
      resetSaveSurvey();
      resetFetchSurvey();
      setInitialingSurveyId(null);
      setSurveyState(initialState);
      setIsSurveyWorkspaceReady(false);
    }
  };

  const saveActiveSurvey = () => {
    if (saveSurveyStatus !== "pending") {
      saveSurvey(state);

      setHasChanges(false);
      idleTimeoutRef.current = null;
    }
  };

  const publishActiveSurvey = () => {
    if (!hasChanges) {
      publishSurvey({
        surveyId: state.id,
        publish: true,
      });
    }
  };

  const unpublishActiveSurvey = () => {
    publishSurvey({
      surveyId: state.id,
      publish: false,
    });
  };

  const setSurveyState = (payload) => {
    dispatch({
      type: ACTIONS.SET_STATE,
      payload,
    });
  };

  const setActiveContentId = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_ID,
      payload,
    });
  };

  const setSurveyType = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_TYPE,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveyName = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_NAME,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveyTrigger = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_TRIGGER,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveyOfferReward = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_OFFER_REWARD,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveyRestrictions = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_RESTRICTIONS,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveyMaxResponses = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_MAX_RESPONSES,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveyLoyaltyPointsOffer = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_LOYALTY_POINTS_OFFER,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveyStartTime = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_START_TIME,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveyEndTime = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_END_TIME,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveyEmailNotifications = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_EMAIL_NOTIFICATIONS,
      payload,
    });

    setHasChanges(true);
  };

  const setSurveySmsNotifications = (payload) => {
    dispatch({
      type: ACTIONS.SET_SURVEY_SMS_NOTIFICATIONS,
      payload,
    });

    setHasChanges(true);
  };

  const addContent = (payload) => {
    dispatch({
      type: ACTIONS.ADD_CONTENT,
      payload,
    });

    setHasChanges(true);
  };

  const removeContent = (payload) => {
    dispatch({
      type: ACTIONS.REMOVE_CONTENT,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentText = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_TEXT,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentDescription = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_DESCRIPTION,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentRequired = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_REQUIRED,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentAllowMultipleSelection = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_ALLOW_MULTIPLE_SELECTION,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentRandomizeOptions = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_RANDOMIZE_OPTIONS,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentAllowOtherOption = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_ALLOW_OTHER_OPTION,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentButtonText = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_BUTTON_TEXT,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentCalculateTimeToComplete = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_CALCULATE_TIME_TO_COMPLETE,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentImage = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_IMAGE,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentImageAlignment = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_IMAGE_ALIGNMENT,
      payload,
    });

    setHasChanges(true);
  };

  const addActiveContentMultiChoiceOption = (payload) => {
    dispatch({
      type: ACTIONS.ADD_ACTIVE_CONTENT_MULTICHOICE_OPTION,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentMultiChoiceOptionValue = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_MULTICHOICE_OPTION_VALUE,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentMaxCharactersLimit = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_MAX_CHARACTERS_LIMIT,
      payload,
    });

    setHasChanges(true);
  };

  const removeActiveContentMultiChoiceOption = (payload) => {
    dispatch({
      type: ACTIONS.REMOVE_ACTIVE_CONTENT_MULTICHOICE_OPTION,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentShouldLimitMaxCharacters = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_SHOULD_LIMIT_MAX_CHARS,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentShowQuotation = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_SHOW_QUOTATION,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentShowLabels = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_SHOW_LABELS,
      payload,
    });

    setHasChanges(true);
  };

  const addActiveContentPictureChoiceOption = (payload) => {
    dispatch({
      type: ACTIONS.ADD_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION,
      payload,
    });

    setHasChanges(true);
  };

  const removeActiveContentPictureChoiceOption = (payload) => {
    dispatch({
      type: ACTIONS.REMOVE_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentPictureChoiceOptionImage = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION_IMAGE,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentPictureChoiceOptionLabel = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION_LABEL,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentOptionIsSelectable = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_OPTION_IS_SELECTABLE,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentHasButton = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_HAS_BUTTON,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentButtonHasLink = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_BUTTON_HAS_LINK,
      payload,
    });

    setHasChanges(true);
  };

  const setActiveContentButtonLink = (payload) => {
    dispatch({
      type: ACTIONS.SET_ACTIVE_CONTENT_BUTTON_LINK,
      payload,
    });

    setHasChanges(true);
  };

  const sortSurveyContentsList = (payload) => {
    dispatch({
      type: ACTIONS.SORT_CONTENTS_LIST,
      payload,
    });

    setHasChanges(true);
  };

  const sortActiveContentOptions = (payload) => {
    dispatch({
      type: ACTIONS.SORT_ACTIVE_CONTENT_OPTIONS,
      payload,
    });

    setHasChanges(true);
  };

  const decideStateValue = (contemplatedState) => {
    const { activeContentId } = state;

    const excludedKeys = [
      "activeContentId",
      "responses",
      "publishedAt",
      "lastUpdateTimestamp",
      "created",
    ];

    const currentState = pickBy(
      state,
      (value, key) => !excludedKeys.includes(key)
    );

    const returnedState = pickBy(
      contemplatedState,
      (value, key) => !excludedKeys.includes(key)
    );

    if (!isEqual(currentState, returnedState)) {
      saveActiveSurvey();
    } else {
      setSurveyState({
        ...contemplatedState,
        activeContentId,
      });

      resetSaveSurvey();
      resetPublishSurvey();
    }
  };

  const toJSON = () => {
    return state;
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (hasChanges) {
        e.preventDefault();

        e.returnValue = "Changes you made may not have been saved.";

        return "Changes you made may not have been saved.";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload, true);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload, true);
    };
  }, [hasChanges]);

  useEffect(() => {
    if (!isSurveyWorkspaceReady) {
      if (state.id) {
        if (state.contents.length && state.activeContentId !== null) {
          setIsSurveyWorkspaceReady(true);
        } else if (!state.contents.length) {
          setIsSurveyWorkspaceReady(true);
        }
      }
    }
  }, [state]);

  useEffect(() => {
    const handleKeydownMousedown = () => {
      if (idleTimeoutRef.current) {
        clearTimeout(idleTimeoutRef.current);

        idleTimeoutRef.current = null;
      }
    };

    const handleKeyupMouseup = () => {
      if (!idleTimeoutRef.current && hasChanges) {
        idleTimeoutRef.current = setTimeout(saveActiveSurvey, 1000);
      }
    };

    document.addEventListener("keydown", handleKeydownMousedown, true);
    document.addEventListener("keyup", handleKeyupMouseup, true);

    document.addEventListener("mousedown", handleKeydownMousedown, true);
    document.addEventListener("mouseup", handleKeyupMouseup, true);

    if (hasChanges) {
      if (!idleTimeoutRef.current) {
        idleTimeoutRef.current = setTimeout(saveActiveSurvey, 1000);
      }
    }

    return () => {
      document.removeEventListener("keydown", handleKeydownMousedown, true);
      document.removeEventListener("keyup", handleKeyupMouseup, true);

      document.removeEventListener("mousedown", handleKeydownMousedown, true);
      document.removeEventListener("mouseup", handleKeyupMouseup, true);
    };
  }, [hasChanges, idleTimeoutRef.current]);

  useEffect(() => {
    if (initialingSurveyId) {
      fetchSurvey(initialingSurveyId);
    }
  }, [initialingSurveyId]);

  useEffect(() => {
    if (publishedSurvey) {
      addSnackbar("Changes published 🎉", {
        type: "success",
      });

      decideStateValue(publishedSurvey);
    }
  }, [publishedSurvey, state]);

  useEffect(() => {
    if (savedSurvey) {
      decideStateValue(savedSurvey);
    }
  }, [savedSurvey, state]);

  useEffect(() => {
    if (saveSurveyError) {
      addSnackbar(saveSurveyError, {
        type: "danger",
      });
    }
  }, [saveSurveyError]);

  useEffect(() => {
    if (publishSurveyError) {
      addSnackbar(publishSurveyError, {
        type: "danger",
      });
    }
  }, [publishSurveyError]);

  useEffect(() => {
    if (fetchSurveyError && surveyMode !== SURVEY_MODES.CREATOR) {
      setInitializationError(fetchSurveyError);
    } else {
      setInitializationError();
    }
  }, [fetchSurveyError, surveyMode]);

  useEffect(() => {
    if (fetchSurveyStatus === "ready") {
      if (!fetchedSurvey) {
        if (surveyMode === SURVEY_MODES.CREATOR) {
          //create a new workspace with the id
          setSurveyState({
            ...initialState,
            id: initialingSurveyId,
          });
        }
      } else {
        setSurveyState({
          ...fetchedSurvey,
          activeContentId: null,
        });

        if (fetchedSurvey?.contents?.length) {
          setActiveContentId({
            activeContentId: fetchedSurvey.contents[0].id,
          });
        }
      }
    }
  }, [surveyMode, initialingSurveyId, fetchedSurvey, fetchSurveyStatus]);

  useEffect(() => {
    if (!state.contents.length) {
      setActiveContent(null);

      setActiveContentId({
        activeContentId: null,
      });
    } else {
      if (state.activeContentId) {
        const content = state.contents.filter((content, index) => {
          if (content.id === state.activeContentId) {
            activeContentIndexRef.current = index;

            return true;
          }

          return false;
        })[0];

        if (!content) {
          if (
            typeof activeContentIndexRef.current === "number" &&
            state.contents.length
          ) {
            if (activeContentIndexRef.current === 0) {
              const content = state.contents[0];

              setActiveContentId({
                activeContentId: content.id,
              });
            } else if (
              activeContentIndexRef.current - 1 >= 0 &&
              activeContentIndexRef.current - 1 < state.contents.length
            ) {
              const content = state.contents[activeContentIndexRef.current - 1];

              setActiveContentId({
                activeContentId: content.id,
              });
            }
          }
        } else {
          setActiveContent(content);
        }
      } else {
        setActiveContentId({
          activeContentId: state.contents[0].id,
        });
      }
    }
  }, [state.contents, state.activeContentId]);

  const value = {
    ...state,
    hasChanges,
    isPublished,
    setHasChanges,
    initializeSurvey,
    initializationError,
    isSurveyWorkspaceReady,
    surveyMode,
    publishActiveSurvey,
    unpublishActiveSurvey,
    isPublishingActiveSurvey: publishSurveyStatus === "pending",
    saveActiveSurvey,
    isSavingActiveSurvey: saveSurveyStatus === "pending",
    activeContent,
    activeContentImageIsUploading,
    addContent,
    addActiveContentMultiChoiceOption,
    addActiveContentPictureChoiceOption,
    sortSurveyContentsList,
    sortActiveContentOptions,
    setSurveyType,
    setSurveyName,
    setSurveyTrigger,
    setSurveyOfferReward,
    setSurveyRestrictions,
    setSurveyMaxResponses,
    setSurveyLoyaltyPointsOffer,
    setSurveyStartTime,
    setSurveyEndTime,
    setSurveyEmailNotifications,
    setSurveySmsNotifications,
    setActiveContentId,
    setActiveContentText,
    setActiveContentDescription,
    setActiveContentRequired,
    setActiveContentAllowMultipleSelection,
    setActiveContentRandomizeOptions,
    setActiveContentAllowOtherOption,
    setActiveContentButtonText,
    setActiveContentCalculateTimeToComplete,
    setActiveContentImage,
    setActiveContentImageAlignment,
    setActiveContentImageIsUploading,
    setActiveContentMultiChoiceOptionValue,
    setActiveContentShouldLimitMaxCharacters,
    setActiveContentMaxCharactersLimit,
    setActiveContentShowQuotation,
    setActiveContentShowLabels,
    setActiveContentPictureChoiceOptionImage,
    setActiveContentPictureChoiceOptionLabel,
    setActiveContentOptionIsSelectable,
    setActiveContentHasButton,
    setActiveContentButtonHasLink,
    setActiveContentButtonLink,
    removeActiveContentPictureChoiceOption,
    removeActiveContentMultiChoiceOption,
    removeContent,
    resetSurvey,
    toJSON,
  };

  return (
    <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>
  );
};
