import { cloneDeep } from "lodash";

export const removeActiveContentPictureChoiceOption = ({
  contents,
  activeContentId,
  optionId,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.options = content.options.filter(
        (option) => option.id !== optionId
      );
    }

    return cloneDeep(content);
  });
};

export default removeActiveContentPictureChoiceOption;
