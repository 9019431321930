//LIBRARY CSS
import "../public/libs/google-fonts/font-family.css";
import "../public/libs/@fontawesome/fontawesome-free/css/all.min.css";
import "../public/vendor/icon-set/style.css";
import "../public/vendor/select2/dist/css/select2.css";
import "../public/vendor/chart.js/dist/Chart.css";
import "../public/vendor/daterangepicker/daterangepicker.css";
import "../public/vendor/quill/dist/quill.snow.css";
import "../public/vendor/@yaireo/tagify/dist/tagify.css";
import "../public/vendor/@fancyapps/fancybox/dist/jquery.fancybox.css";
import "../public/vendor/flatpickr/dist/flatpickr.min.css";
import "react-placeholder/lib/reactPlaceholder.css";

//THEME CSS
import "../public/css/theme.css";

//SUPPLEMENTAL CSS
import "../public/css/extras/main.css";
import "../public/css/extras/notice.css";
import "../public/css/extras/errors.css";
import "../public/css/extras/tables.css";
import "../public/css/extras/snackbar.css";
import "../public/css/extras/survey-editor.css";
import "../public/css/extras/survey-summary.css";
import "../public/css/extras/survey-responses.css";
import "../public/css/extras/shipments.css";
import "../public/css/extras/cursor.css";

//REACT
import React from "react";

//COMPONENTS
import AppShell from "../components/AppShell";

//Global Providers
import { GlobalProvider } from "../state/contexts/globalState";
import { AuthProvider } from "../state/contexts/authState";
import { SnackbarProvider } from "../state/contexts/snackbarState";
import { SurveyProvider } from "../state/contexts/surveyState";
import { SurveyResponseProvider } from "../state/contexts/surveyResponseState";

function App({ Component, pageProps }) {
  return (
    <GlobalProvider>
      <SnackbarProvider>
        <AuthProvider>
          <SurveyProvider>
            <SurveyResponseProvider>
              <AppShell>
                <Component {...pageProps} />
              </AppShell>
            </SurveyResponseProvider>
          </SurveyProvider>
        </AuthProvider>
      </SnackbarProvider>
    </GlobalProvider>
  );
}

export default App;
