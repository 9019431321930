import { cloneDeep } from "lodash";

export const setActiveContentImageAlignment = ({
  contents,
  activeContentId,
  imageAlignment,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.imageAlignment = imageAlignment;
    }

    return cloneDeep(content);
  });
};

export default setActiveContentImageAlignment;
