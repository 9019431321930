export const setActiveContentDescription = ({
  contents,
  activeContentId,
  description,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.content.description = description;
    }

    return content;
  });
};

export default setActiveContentDescription;
