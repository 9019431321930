import { cloneDeep } from "lodash";

export const setActiveContentHasButton = ({
  contents,
  activeContentId,
  hasButton,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.hasButton = hasButton;
    }

    return cloneDeep(content);
  });
};

export default setActiveContentHasButton;
