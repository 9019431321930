import { cloneDeep } from "lodash";

export const setActiveContentButtonText = ({
  contents,
  activeContentId,
  text,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.ui.button.text = text;
    }

    return cloneDeep(content);
  });
};

export default setActiveContentButtonText;
