import { useEffect, useState } from "react";
import { useApiCall } from "./useApiCall";

export const usePublishSurvey = () => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [publishSurveyStatus, setPublishSurveyStatus] = useState("idle");
  const [publishedSurvey, setPublishedSurvey] = useState();
  const [publishSurveyError, setPublishSurveyError] = useState();

  const publishSurvey = (opts) => {
    setPublishSurveyError();
    setPublishedSurvey();

    const requestOptions = {
      url: `/surveys/${opts.surveyId}`,
      method: "POST",
      data: opts,
    };

    setPublishSurveyStatus("pending");
    executeApiCall(requestOptions);
  };

  const resetPublishSurvey = () => {
    setPublishSurveyStatus("idle");
    setPublishedSurvey();
    setPublishSurveyError();
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse.data.error) {
          setPublishSurveyError(apiCallResponse.data.error.userMessage);
          setPublishSurveyStatus("ready");
        } else {
          setPublishSurveyError();

          if (apiCallResponse.data.data) {
            const { survey } = apiCallResponse.data.data;

            setPublishSurveyStatus("ready");
            setPublishedSurvey(survey);
          }
        }
        break;
      case "error":
        setPublishSurveyStatus("ready");
    }
  }, [apiCallStatus, apiCallResponse]);

  return {
    publishSurvey,
    publishedSurvey,
    publishSurveyError,
    publishSurveyStatus,
    resetPublishSurvey,
  };
};
