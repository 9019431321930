import { cloneDeep } from "lodash";

export const setActiveContentPictureChoiceOptionImage = ({
  contents,
  activeContentId,
  data,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.options = content.options.map((option) => {
        if (option.id === data.id) {
          option.image = data.image;
        }

        return option;
      });
    }

    return cloneDeep(content);
  });
};

export default setActiveContentPictureChoiceOptionImage;
