import { ACTIONS } from "../contexts/surveyState";
import addContent from "./functions/survey/addContent";
import addActiveContentMultiChoiceOption from "./functions/survey/addActiveContentMultiChoiceOption";
import addActiveContentPictureChoiceOption from "./functions/survey/addActiveContentPictureChoiceOption";
import sortSurveyContentsList from "./functions/survey/sortSurveyContentsList";
import sortActiveContentOptions from "./functions/survey/sortActiveContentOptions";
import setActiveContentText from "./functions/survey/setActiveContentText";
import setActiveContentDescription from "./functions/survey/setActiveContentDescription";
import setActiveContentRequired from "./functions/survey/setActiveContentRequired";
import setActiveContentAllowMultipleSelection from "./functions/survey/setActiveContentAllowMultipleSelection";
import setActiveContentRandomizeOptions from "./functions/survey/setActiveContentRandomizeOptions";
import setActiveContentAllowOtherOption from "./functions/survey/setActiveContentAllowOtherOption";
import setActiveContentImage from "./functions/survey/setActiveContentImage";
import setActiveContentImageAlignment from "./functions/survey/setActiveContentImageAlignment";
import setActiveContentButtonText from "./functions/survey/setActiveContentButtonText";
import setActiveContentCalculateTimeToComplete from "./functions/survey/setActiveContentCalculateTimeToComplete";
import setActiveContentMultiChoiceOptionValue from "./functions/survey/setActiveContentMultiChoiceOptionValue";
import setActiveContentShouldLimitMaxCharacters from "./functions/survey/setActiveContentShouldLimitMaxCharacters";
import setActiveContentMaxCharactersLimit from "./functions/survey/setActiveContentMaxCharactersLimit";
import setActiveContentShowQuotation from "./functions/survey/setActiveContentShowQuotation";
import setActiveContentShowLabels from "./functions/survey/setActiveContentShowLabels";
import setActiveContentPictureChoiceOptionImage from "./functions/survey/setActiveContentPictureChoiceOptionImage";
import setActiveContentPictureChoiceOptionLabel from "./functions/survey/setActiveContentPictureChoiceOptionLabel";
import setActiveContentOptionIsSelectable from "./functions/survey/setActiveContentOptionIsSelectable";
import setActiveContentHasButton from "./functions/survey/setActiveContentHasButton";
import setActiveContentButtonHasLink from "./functions/survey/setActiveContentButtonHasLink";
import setActiveContentButtonLink from "./functions/survey/setActiveContentButtonLink";
import setSurveyStartTime from "./functions/survey/setSurveyStartTime";
import setSurveyEndTime from "./functions/survey/setSurveyEndTime";
import setSurveyOfferReward from "./functions/survey/setSurveyOfferReward";
import setSurveyLoyaltyPointsOffer from "./functions/survey/setSurveyLoyaltyPointsOffer";
import setSurveyEmailNotifications from "./functions/survey/setSurveyEmailNotifications";
import setSurveySmsNotifications from "./functions/survey/setSurveySmsNotifications";
import setSurveyTrigger from "./functions/survey/setSurveyTrigger";
import removeActiveContentMultiChoiceOption from "./functions/survey/removeActiveContentMultiChoiceOption";
import removeActiveContentPictureChoiceOption from "./functions/survey/removeActiveContentPictureChoiceOption";
import removeContent from "./functions/survey/removeContent";

const surveyReducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.SET_STATE: {
      return action.payload;
    }

    case ACTIONS.SET_SURVEY_TYPE: {
      const { surveyType } = action.payload;

      return { ...state, surveyType };
    }

    case ACTIONS.SET_SURVEY_NAME: {
      const { surveyName } = action.payload;

      return { ...state, surveyName };
    }

    case ACTIONS.SET_SURVEY_OFFER_REWARD: {
      const { offer } = action.payload;

      const reward = setSurveyOfferReward({
        reward: state.reward,
        offer,
      });

      return { ...state, reward };
    }

    case ACTIONS.SET_SURVEY_RESTRICTIONS: {
      const { restrictions } = action.payload;

      return { ...state, restrictions };
    }

    case ACTIONS.SET_SURVEY_TRIGGER: {
      const { path } = action.payload;

      const triggers = setSurveyTrigger({
        triggers: state.triggers,
        path,
      });

      return { ...state, triggers };
    }

    case ACTIONS.SET_SURVEY_MAX_RESPONSES: {
      const { maxResponses } = action.payload;

      return { ...state, maxResponses };
    }

    case ACTIONS.SET_SURVEY_LOYALTY_POINTS_OFFER: {
      const { loyaltyPoints } = action.payload;

      const reward = setSurveyLoyaltyPointsOffer({
        reward: state.reward,
        loyaltyPoints,
      });

      return { ...state, reward };
    }

    case ACTIONS.SET_SURVEY_START_TIME: {
      const { startTime } = action.payload;

      const schedule = setSurveyStartTime({
        schedule: state.schedule,
        startTime,
      });

      return { ...state, schedule };
    }

    case ACTIONS.SET_SURVEY_END_TIME: {
      const { endTime } = action.payload;

      const schedule = setSurveyEndTime({
        schedule: state.schedule,
        endTime,
      });

      return { ...state, schedule };
    }

    case ACTIONS.SET_SURVEY_EMAIL_NOTIFICATIONS: {
      const { email } = action.payload;

      const notifications = setSurveyEmailNotifications({
        notifications: state.notifications,
        email,
      });

      return { ...state, notifications };
    }

    case ACTIONS.SET_SURVEY_SMS_NOTIFICATIONS: {
      const { sms } = action.payload;

      const notifications = setSurveySmsNotifications({
        notifications: state.notifications,
        sms,
      });

      return { ...state, notifications };
    }

    case ACTIONS.SORT_CONTENTS_LIST: {
      const data = action.payload;

      const contents = sortSurveyContentsList({
        contents: state.contents,
        data,
      });

      return { ...state, contents };
    }

    case ACTIONS.SORT_ACTIVE_CONTENT_OPTIONS: {
      const data = action.payload;

      const contents = sortActiveContentOptions({
        contents: state.contents,
        activeContentId: state.activeContentId,
        data,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_ID: {
      const { activeContentId } = action.payload;

      return { ...state, activeContentId };
    }

    case ACTIONS.ADD_CONTENT: {
      const { content } = action.payload;

      const { contents, activeContentId } = addContent({
        contents: state.contents,
        content,
      });

      return { ...state, contents, activeContentId };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_TEXT: {
      const { text } = action.payload;

      const contents = setActiveContentText({
        contents: state.contents,
        activeContentId: state.activeContentId,
        text,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_DESCRIPTION: {
      const { description } = action.payload;

      const contents = setActiveContentDescription({
        contents: state.contents,
        activeContentId: state.activeContentId,
        description,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_REQUIRED: {
      const { required } = action.payload;

      const contents = setActiveContentRequired({
        contents: state.contents,
        activeContentId: state.activeContentId,
        required,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_ALLOW_MULTIPLE_SELECTION: {
      const { allowMultipleSelection } = action.payload;

      const contents = setActiveContentAllowMultipleSelection({
        contents: state.contents,
        activeContentId: state.activeContentId,
        allowMultipleSelection,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_RANDOMIZE_OPTIONS: {
      const { randomizeOptions } = action.payload;

      const contents = setActiveContentRandomizeOptions({
        contents: state.contents,
        activeContentId: state.activeContentId,
        randomizeOptions,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_ALLOW_OTHER_OPTION: {
      const { allowOtherOption } = action.payload;

      const contents = setActiveContentAllowOtherOption({
        contents: state.contents,
        activeContentId: state.activeContentId,
        allowOtherOption,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_BUTTON_TEXT: {
      const { text } = action.payload;

      const contents = setActiveContentButtonText({
        contents: state.contents,
        activeContentId: state.activeContentId,
        text,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_CALCULATE_TIME_TO_COMPLETE: {
      const { calculateTimeToComplete } = action.payload;

      const contents = setActiveContentCalculateTimeToComplete({
        contents: state.contents,
        activeContentId: state.activeContentId,
        calculateTimeToComplete,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_IMAGE: {
      const { image } = action.payload;

      const contents = setActiveContentImage({
        contents: state.contents,
        activeContentId: state.activeContentId,
        image,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_IMAGE_ALIGNMENT: {
      const { imageAlignment } = action.payload;

      const contents = setActiveContentImageAlignment({
        contents: state.contents,
        activeContentId: state.activeContentId,
        imageAlignment,
      });

      return { ...state, contents };
    }

    case ACTIONS.ADD_ACTIVE_CONTENT_MULTICHOICE_OPTION: {
      const { option } = action.payload;

      const contents = addActiveContentMultiChoiceOption({
        contents: state.contents,
        activeContentId: state.activeContentId,
        option,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_MULTICHOICE_OPTION_VALUE: {
      const data = action.payload;

      const contents = setActiveContentMultiChoiceOptionValue({
        contents: state.contents,
        activeContentId: state.activeContentId,
        data,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_SHOULD_LIMIT_MAX_CHARS: {
      const { shouldLimitMaxCharacters } = action.payload;

      const contents = setActiveContentShouldLimitMaxCharacters({
        contents: state.contents,
        activeContentId: state.activeContentId,
        shouldLimitMaxCharacters,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_MAX_CHARACTERS_LIMIT: {
      const { limit } = action.payload;

      const contents = setActiveContentMaxCharactersLimit({
        contents: state.contents,
        activeContentId: state.activeContentId,
        limit,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_SHOW_QUOTATION: {
      const { showQuotation } = action.payload;

      const contents = setActiveContentShowQuotation({
        contents: state.contents,
        activeContentId: state.activeContentId,
        showQuotation,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_SHOW_LABELS: {
      const { showLabels } = action.payload;

      const contents = setActiveContentShowLabels({
        contents: state.contents,
        activeContentId: state.activeContentId,
        showLabels,
      });

      return { ...state, contents };
    }

    case ACTIONS.REMOVE_CONTENT: {
      const { contentId } = action.payload;

      const contents = removeContent({
        contents: state.contents,
        contentId,
      });

      return { ...state, contents };
    }

    case ACTIONS.REMOVE_ACTIVE_CONTENT_MULTICHOICE_OPTION: {
      const { optionId } = action.payload;

      const contents = removeActiveContentMultiChoiceOption({
        contents: state.contents,
        activeContentId: state.activeContentId,
        optionId,
      });

      return { ...state, contents };
    }

    case ACTIONS.ADD_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION: {
      const { option } = action.payload;

      const contents = addActiveContentPictureChoiceOption({
        contents: state.contents,
        activeContentId: state.activeContentId,
        option,
      });

      return { ...state, contents };
    }

    case ACTIONS.REMOVE_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION: {
      const { optionId } = action.payload;

      const contents = removeActiveContentPictureChoiceOption({
        contents: state.contents,
        activeContentId: state.activeContentId,
        optionId,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION_IMAGE: {
      const data = action.payload;

      const contents = setActiveContentPictureChoiceOptionImage({
        contents: state.contents,
        activeContentId: state.activeContentId,
        data,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_PICTURE_CHOICE_OPTION_LABEL: {
      const data = action.payload;

      const contents = setActiveContentPictureChoiceOptionLabel({
        contents: state.contents,
        activeContentId: state.activeContentId,
        data,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_OPTION_IS_SELECTABLE: {
      const data = action.payload;

      const contents = setActiveContentOptionIsSelectable({
        contents: state.contents,
        activeContentId: state.activeContentId,
        data,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_HAS_BUTTON: {
      const { hasButton } = action.payload;

      const contents = setActiveContentHasButton({
        contents: state.contents,
        activeContentId: state.activeContentId,
        hasButton,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_BUTTON_LINK: {
      const { buttonLink } = action.payload;

      const contents = setActiveContentButtonLink({
        contents: state.contents,
        activeContentId: state.activeContentId,
        buttonLink,
      });

      return { ...state, contents };
    }

    case ACTIONS.SET_ACTIVE_CONTENT_BUTTON_HAS_LINK: {
      const { buttonHasLink } = action.payload;

      const contents = setActiveContentButtonHasLink({
        contents: state.contents,
        activeContentId: state.activeContentId,
        buttonHasLink,
      });

      return { ...state, contents };
    }

    default:
      return state;
  }
};

export default surveyReducer;
