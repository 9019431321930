import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../state/contexts/authState";
import { useApiCall } from "./useApiCall";
import { useRedirect } from "./useRedirect";

export const useLogout = (shouldRedirect = true) => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [logoutError, setLogoutError] = useState("");
  const [logoutStatus, setLogoutStatus] = useState("idle");
  const { setAccessToken, setUser } = useContext(AuthContext);
  const { redirect } = useRedirect();

  const logout = () => {
    const requestOptions = {
      url: "/auth/logout",
    };

    executeApiCall(requestOptions);

    setLogoutStatus("pending");
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse) {
          const { data: requestData } = apiCallResponse;

          const { error, success } = requestData;

          if (error) {
            setLogoutStatus("error");
            setLogoutError(error.userMessage);
          }

          if (success === true) {
            setAccessToken({
              accessToken: null,
            });

            setUser({
              user: null,
            });

            setLogoutStatus("success");

            if (shouldRedirect) {
              redirect("/");
            }
          }
        }
        break;
    }
  }, [apiCallResponse, apiCallStatus]);

  return {
    logout,
    logoutError,
    logoutStatus,
  };
};
