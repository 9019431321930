import React, { useEffect, useState } from "react";
import { useApiCall } from "./useApiCall";

export const useLogin = () => {
  const { executeApiCall, apiCallResponse, apiCallStatus } = useApiCall();
  const [loginError, setLoginError] = useState("");
  const [loginStatus, setLoginStatus] = useState("idle");

  const login = (data) => {
    const requestOptions = {
      url: "/auth/authenticate",
      data,
      method: "POST",
    };

    executeApiCall(requestOptions);

    setLoginStatus("pending");
    setLoginError();
  };

  useEffect(() => {
    switch (apiCallStatus) {
      case "success":
        if (apiCallResponse) {
          const { data: requestData } = apiCallResponse;

          const { error, success } = requestData;

          if (error) {
            setLoginStatus("error");
            setLoginError(error.userMessage);
          }

          if (success === true) {
            setLoginStatus("success");
          }
        }
        break;
    }
  }, [apiCallResponse, apiCallStatus]);

  return {
    login,
    loginError,
    loginStatus,
  };
};
