import generateGenericId from "../../../../util/generateGenericId";
import { cloneDeep } from "lodash";
import { SURVEY_CONTENT_TYPES } from "../../../../services/lib/surveys/constants";

export const addContent = ({ contents, content }) => {
  const contentId = generateGenericId(18);

  if (content.type === SURVEY_CONTENT_TYPES.WELCOME_SCREEN) {
    contents = [
      {
        id: contentId,
        ...content,
      },
      ...contents,
    ];
  } else {
    contents = [
      ...contents,
      {
        id: contentId,
        ...content,
      },
    ];
  }

  const hasEndingScreen = contents.some(
    ({ type }) => type === SURVEY_CONTENT_TYPES.ENDING_SCREEN
  );

  if (hasEndingScreen) {
    const endingIndex = contents.findIndex(
      ({ type }) => type === SURVEY_CONTENT_TYPES.ENDING_SCREEN
    );

    if (endingIndex !== -1) {
      contents.splice(
        contents.length - 1,
        0,
        contents.splice(endingIndex, 1)[0]
      );
    }
  }

  return cloneDeep({
    contents,
    activeContentId: contentId,
  });
};

export default addContent;
