import { cloneDeep } from "lodash";

export const setActiveContentImage = ({ contents, activeContentId, image }) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.image = image;
    }

    return cloneDeep(content);
  });
};

export default setActiveContentImage;
