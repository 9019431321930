import { cloneDeep } from "lodash";
import arrayMoveImmutable from "../../../../util/arrayMoveImmutable";

export const sortActiveContentOptions = ({
  contents,
  activeContentId,
  data,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      const { from, to } = data;

      const arr = arrayMoveImmutable(content.options, from, to);

      content.options = arr;
    }

    return cloneDeep(content);
  });
};

export default sortActiveContentOptions;
