import { cloneDeep } from "lodash";

export const setActiveContentButtonHasLink = ({
  contents,
  activeContentId,
  buttonHasLink,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.buttonHasLink = buttonHasLink;
    }

    return cloneDeep(content);
  });
};

export default setActiveContentButtonHasLink;
