import React from "react";
import GlobalHead from "./GlobalHead";
import Loader from "./Loader";
import { useAuth } from "../services/hooks/useAuth";

export default function AppShell({ description, title, children }) {
  const { authStatus } = useAuth();

  if (authStatus !== "ready") {
    return (
      <React.Fragment>
        <GlobalHead description={description} title={title} />
        <Loader absoluteCenter />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <GlobalHead description={description} title={title} />
      {children}
    </React.Fragment>
  );
}
