import { cloneDeep } from "lodash";

export const setActiveContentMaxCharactersLimit = ({
  contents,
  activeContentId,
  limit,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.maxCharacters = {
        ...content.maxCharacters,
        limit,
      };
    }

    return cloneDeep(content);
  });
};

export default setActiveContentMaxCharactersLimit;
