import { cloneDeep } from "lodash";

export const setActiveContentOptionIsSelectable = ({
  contents,
  activeContentId,
  data,
}) => {
  return contents.map((content) => {
    if (content.id === activeContentId) {
      content.options = content.options.map((option) => {
        if (option.id === data.id) {
          option.isSelectable = data.isSelectable;
        }

        return option;
      });
    }

    return cloneDeep(content);
  });
};

export default setActiveContentOptionIsSelectable;
